<template>
  <div class="post-card" :class="{ 'profile-hidden': hideProfile }" @click="onViewPost(data?.PostKey)">
    <div class="image">
      <!-- <div class="bg-sommelier-logo" v-if="data?.IsInfluencer && data?.IsInfluencer > 0"><img :src="require('@/assets/images/sommelier-logo2.png')"></div> -->

      <img v-if="data?.IsInfluencer && data?.IsInfluencer > 0" :src="require('@/assets/images/sommelier-logo2.png')" class="sommelier-logo" loading="lazy" />

      <template v-if="data?.PostVideoPoster">
        <div>
          <img :src="$h.getPoster(data?.PostVideoPoster)" :alt="$t.getTranslation('LBL_POST_IMAGE')" loading="lazy" />
          <div class="video-tag">
            <font-awesome-icon :icon="['fas', 'play']" fixed-width />
          </div>
        </div>
      </template>
      <template v-else>
        <img v-if="data?.PostImage && ['POST', 'TASTINGRECORD', 'IMAGE', 'ARTICLE'].indexOf(data?.PostType) > -1" loading="lazy" :src="$h.getImage(data?.PostImage, 'POST', null, true)" :alt="$t.getTranslation('LBL_POST_IMAGE')" />

        <div v-if="data?.PostVideo && ['POST', 'TASTINGRECORD', 'VIDEO'].indexOf(data?.PostType) > -1">
          <img :src="$h.getVideo(data?.PostVideo, 'POST', null, true)" :alt="$t.getTranslation('LBL_POST_IMAGE')" loading="lazy" />
          <div class="video-tag">
            <font-awesome-icon :icon="['fas', 'play']" fixed-width />
          </div>
        </div>
      </template>

      <span v-if="data?.TotalPostMedia > 1" class="post-type">1 / {{ data?.TotalPostMedia || 0 }}</span>
    </div>

    <div v-if="!hideProfile" class="profile" @click.stop="onViewInfluencer(data?.UserKey)">
      <img :src="$h.getImage(data?.UserImage, 'USER')" :alt="$t.getTranslation('LBL_PROFILE_PICTURE')" loading="lazy" />
      <h3 v-if="!hideProfileName">{{ data?.UserName || data?.FirstName || data?.LastName }}</h3>

      <div class="options">
        <f7-link @click.stop="likeToggle(data?.PostKey)">
          <font-awesome-icon v-if="data?.IsLike === 1" :icon="['fas', 'heart']" fixed-width class="fill" />
          <font-awesome-icon v-else :icon="['far', 'heart']" fixed-width />

          <span v-if="data?.TotalLike > 0">{{ $h.formatDisplayNumber(data?.TotalLike) }}</span>
        </f7-link>

        <f7-link @click.stop="showComments">
          <font-awesome-icon :icon="['far', 'comments']" fixed-width />

          <span v-if="data?.TotalComment > 0">{{ $h.formatDisplayNumber(data?.TotalComment) }}</span>
        </f7-link>

        <f7-link @click.stop="showShare">
          <font-awesome-icon :icon="['far', 'share']" fixed-width />
        </f7-link>
      </div>
    </div>

    <div v-if="data?.PostTitle" class="content">
      <h1>{{ $h.formatTrimString(data?.PostTitle, 80) }}</h1>
    </div>

    <div v-if="data?.PostHighlight" class="content">
      <p>{{ $h.formatTrimString(data?.PostHighlight, 80) }}</p>
    </div>

    <div v-if="!data?.PostHighlight && data?.PostText" class="content">
      <p>{{ $h.formatTrimString(data?.PostText, 80) }}</p>
    </div>
  </div>

  <Suspense>
    <PostCommentComponent v-if="data && data.PostKey !== ''" :lazy="true" ref="postCommentComponent" :key="data.PostKey" :post-data="data" @updateTotalComment="updateTotalComment" />
  </Suspense>

  <Suspense>
    <ShareComponent
      v-if="data && data.PostKey !== ''"
      ref="shareComponent"
      :lazy="true"
      :share-data="{
        type: 'POST',
        key: data.PostKey,
        title: data.PostTitle || data.PostText,
        description: data.PostText,
        qoute: data.PostText,
        hashtags: data?.PostTagList,
        media: $h.getImage(data.PostImage, 'POST'),
      }"
    />
  </Suspense>
</template>

<script>
import { defineComponent, inject, toRefs, defineAsyncComponent } from "vue";
import { useStore } from "@/store";

import { f7 } from "framework7-vue";

// import PostCommentComponent from '@/components/PostCommentComponent.vue'
// import ShareComponent from '@/components/ShareComponent.vue'

import { post } from "@/utils/axios";
import { helpers } from "@/utils/helpers";

export default defineComponent({
  name: "PostCardComponent",
  components: {
    PostCommentComponent: defineAsyncComponent(() => import(/* webpackChunkName: "post-comment" */ /* webpackMode: "lazy" */ "@/components/PostCommentComponent.vue")),
    ShareComponent: defineAsyncComponent(() => import(/* webpackChunkName: "share" */ /* webpackMode: "lazy" */ "@/components/ShareComponent.vue")),
  },
  props: {
    data: Object,
    showPostType: {
      type: Boolean,
      default: false,
    },
    hideProfile: {
      type: Boolean,
      default: false,
    },
    hideProfileName: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();

    const $f7router = f7.views.get("#main-view").router;
    const { data } = toRefs(props);

    const onViewPost = (key) => {
      switch (props?.data?.PostType) {
        case "POST":
        case "IMAGE":
          $f7router.navigate({ name: "postViewPage", params: { key } });
          break;
        case "VIDEO":
          $f7router.navigate({ name: "videoViewPage", params: { key } });
          break;
        case "ARTICLE":
          $f7router.navigate({ name: "articleViewPage", params: { key } });
          break;
        default:
          break;
      }
    };

    const likeToggle = async (PostKey) => {
      if (!helpers.isUserLogin()) {
        $f7router.navigate({ name: "loginPage" });
        return;
      }

      await post("/post/like/toggle", { PostKey: PostKey });

      if (data.value.IsLike === 1) {
        data.value.IsLike = 0;
        data.value.TotalLike--;
      } else {
        data.value.IsLike = 1;
        data.value.TotalLike++;
      }
    };

    const updateTotalComment = (count) => {
      data.value.TotalComment = data.value.TotalComment + count;
    };

    const onViewInfluencer = (userKey) => {
      if (userKey && userKey != "") $f7router.navigate({ name: "influencerViewPage", params: { code: userKey } });
    };

    return {
      onViewPost,
      likeToggle,
      onViewInfluencer,
      updateTotalComment,
      store,
    };
  },
  methods: {
    showComments() {
      this.$refs.postCommentComponent.showCommentSheet(this.data.PostKey, true);
    },
    showShare() {
      this.$refs.shareComponent.showShareSheet(true);
    },
  },
});
</script>

<style>
.video-tag {
  position: absolute;
  z-index: 999;
  bottom: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.hideProfile {
  display: none;
}
.post-card .image img.sommelier-logo {
  width: 40px;
  position: absolute;
  top: 7px;
  left: 7px;
  z-index: 10;
}
/* .bg-sommelier-logo img {
  position: relative;
  width: 25px !important;
  top: 8px;
  left: 5px;
}
.bg-sommelier-logo {
  position: absolute;
  width: 35px;
  height: 35px;
  background: white;
  top: 10px;
  left: 5px;
  border-radius: 50px;
} */
</style>
